import { apiUnixToDate } from "@warrenio/api-spec/conversion";
import type { Campaigns } from "@warrenio/api-spec/spec.oats.gen";
import { Badge } from "../../components/Badge.tsx";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { ClipBoardTooltip } from "../../components/ClipBoardTooltip.tsx";
import { CurrencyPromo } from "../../components/l10n/Currency.tsx";
import { LongDate } from "../../components/l10n/DateFormat.tsx";
import { DeleteModal } from "../../components/modal/DeleteModal.tsx";
import { WTableBody } from "../../components/table/WTable.tsx";
import { InfoTooltip } from "../../components/WTooltip.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { getReferralUrl } from "../../modules/billing/referral/referralLogic.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AdminTitle } from "../AdminTitle.tsx";
import { WAdminTable } from "../WAdminTable.tsx";
import { AddCampaignButton, EditCampaignButton } from "./AddCampaignModal.tsx";
import { campaignDeleteMutation, campaignsQueryAtom, campaignTypeMap } from "./campaignsQuery.ts";

export function CampaignsView() {
    const deleteMutation = useStandardMutation(campaignDeleteMutation);
    const campaigns = useSuspenseQueryAtom(campaignsQueryAtom);

    async function onDelete({ id }: Campaigns) {
        await deleteMutation.mutateAsync({ id });
    }

    return (
        <>
            <AdminTitle title="Campaigns">
                <AddCampaignButton button={<WModalButton label="New" color="primary" size="bar" variant="basic" />} />
            </AdminTitle>

            <WAdminTable>
                <thead>
                    <tr>
                        <th>Code</th>
                        <th>Url</th>
                        <th>Description</th>
                        <th className="text-right">Credit Amount</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Created At</th>
                        <th className="text-right">
                            <div className="flex justify-end">
                                Used Count
                                <InfoTooltip
                                    className="ml-1"
                                    text="The count here reflects how many times the campaign has been successfully used. Results in billing account table may not always reflect as accurate usage statistics. Read more from documentation."
                                />
                            </div>
                        </th>
                        <th />
                    </tr>
                </thead>
                <WTableBody>
                    {campaigns.map((item) => {
                        const {
                            id,
                            campaign_code,
                            campaign_description,
                            credit_amount,
                            campaign_type,
                            is_active,
                            created_at,
                            used_count,
                        } = item;
                        return (
                            <tr key={id}>
                                <td>
                                    <ClipBoardTooltip>{campaign_code}</ClipBoardTooltip>
                                </td>
                                <td>
                                    {item.campaign_type === "new_users" ? (
                                        <ClipBoardTooltip>{getReferralUrl(campaign_code)}</ClipBoardTooltip>
                                    ) : (
                                        "-"
                                    )}
                                </td>
                                <td>{campaign_description}</td>
                                <td className="text-right">
                                    <CurrencyPromo value={credit_amount} />
                                </td>
                                <td>{campaignTypeMap[campaign_type]}</td>
                                <td>
                                    <ActiveBadge value={is_active} />
                                </td>
                                <td>
                                    <LongDate date={apiUnixToDate(created_at)} />
                                </td>
                                <td className="text-right">{used_count}</td>
                                <td className="text-right">
                                    <div className="flex gap-0.5em justify-end">
                                        <EditCampaignButton
                                            item={item}
                                            button={<WModalButton label="Edit" inTable icon="jp-icon-edit" />}
                                        />

                                        <DeleteModal
                                            title="Delete Campaign"
                                            inTable
                                            modalAction={async () => await onDelete(item)}
                                        >
                                            Are you sure you want to delete campaign "<b>{campaign_description}</b>"
                                            with code "<b>{campaign_code}</b>"?
                                        </DeleteModal>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </WTableBody>
            </WAdminTable>

            <div className="p-2">
                <AddCampaignButton
                    button={
                        <WModalButton
                            className="w-full"
                            label="Add Campaign"
                            color="primary"
                            size="lg"
                            variant="dashed"
                            icon="jp-icon-add"
                        />
                    }
                />
            </div>
        </>
    );
}

function ActiveBadge({ value }: { value: boolean }) {
    return <Badge color={value ? "success" : "error"}>{value ? "Active" : "Inactive"}</Badge>;
}
