import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { Campaigns, CampaignsCreateBody, CampaignsUpdateBody } from "@warrenio/api-spec/spec.oats.gen";
import type { ApiClient } from "../../modules/api/apiClient";
import { getResponseData } from "../../modules/api/apiClient";
import { jsonEncodedBody } from "../../utils/fetchClient.ts";
import { adminMutation } from "../../utils/query/adminMutation.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";

//#region Constants
export const campaignTypeMap = { new_users: "New Users", all_users: "All Users" };
//#endregion

//#region Response
export type Response = Campaigns[];
//#endregion

//#region Query and parameters
export const queryKey: QueryKey = ["campaigns"];

export function getQuery(client: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<Response> => {
            return getResponseData(await client.GET("/payment/admin/campaign/list", { signal }));
        },
    });
}
//#endregion

//#region Atoms
export const campaignsQueryAtom = atomFromStandardQueryOptions(getQuery);
//#endregion

//#region Mutations

export const campaignCreateMutation = adminMutation((apiClient) => ({
    entity: "campaign",
    operation: "create",
    requestFn: async ({ body }: { body: CampaignsCreateBody }) =>
        await apiClient.POST("/payment/admin/campaign", {
            ...jsonEncodedBody,
            body,
        }),
    invalidateQueryKey: queryKey,
}));

export const campaignUpdateMutation = adminMutation((apiClient) => ({
    entity: "campaign",
    operation: "create",
    requestFn: async ({ id, body }: { id: Campaigns["id"]; body: CampaignsUpdateBody }) =>
        await apiClient.PUT("/payment/admin/campaign/{id}", {
            params: { path: { id: String(id) } },
            ...jsonEncodedBody,
            body,
        }),
    invalidateQueryKey: queryKey,
}));

export const campaignDeleteMutation = adminMutation((apiClient) => ({
    entity: "campaign",
    operation: "delete",
    requestFn: async ({ id }: { id: Campaigns["id"] }) =>
        await apiClient.DELETE("/payment/admin/campaign/{id}", {
            params: { path: { id: String(id) } },
        }),
    invalidateQueryKey: queryKey,
}));

//#endregion Mutations
