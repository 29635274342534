import type { Campaigns, CampaignsType } from "@warrenio/api-spec/spec.oats.gen";
import type { FormEvent, ReactNode } from "react";
import { useState } from "react";
import { WSelect } from "../../components/forms/WSelect.tsx";
import { WTextField } from "../../components/forms/WTextField.tsx";
import LR from "../../components/LeftRight.module.css";
import { LeftRight } from "../../components/LeftRight.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { AInput } from "../form/Fields.tsx";
import { campaignCreateMutation, campaignTypeMap, campaignUpdateMutation } from "./campaignsQuery.ts";

export function AddCampaignButton({ button }: { button: ReactNode }) {
    return (
        <WModal button={button}>
            <AddEditCampaignModalContent />
        </WModal>
    );
}

export function EditCampaignButton({ item, button }: { item: Campaigns; button: ReactNode }) {
    return (
        <WModal button={button}>
            <AddEditCampaignModalContent item={item} />
        </WModal>
    );
}

interface UserType {
    id: CampaignsType;
    title: string;
}

interface statusType {
    id: string;
    title: string;
}

export function AddEditCampaignModalContent({ item }: { item?: Campaigns }) {
    const createMutation = useStandardMutation(campaignCreateMutation);
    const updateMutation = useStandardMutation(campaignUpdateMutation);

    const [code, setCode] = useState<string>(item?.campaign_code ?? "");
    const [description, setDescription] = useState<string>(item?.campaign_description ?? "");
    const [type, setType] = useState<CampaignsType>(item?.campaign_type ?? "new_users");
    const [amount, setAmount] = useState<string | number>(item?.credit_amount ?? "");
    const [isActive, setIsActive] = useState<boolean>(item?.is_active ?? true);

    const isEditMode = !!item;
    const typeItems: UserType[] = Object.entries(campaignTypeMap).map(([id, title]) => ({
        id: id as CampaignsType,
        title,
    }));

    const statusItems: statusType[] = [
        { id: "active", title: "Active" },
        { id: "inactive", title: "Inactive" },
    ];

    async function onSubmit() {
        if (isEditMode) {
            await updateMutation.mutateAsync({
                body: {
                    campaign_description: description,
                    is_active: isActive,
                },
                id: item.id,
            });
        } else {
            await createMutation.mutateAsync({
                body: {
                    campaign_code: code,
                    campaign_description: description,
                    campaign_type: type,
                    credit_amount: amount ? Number(amount) : 0,
                    is_active: isActive,
                },
            });
        }
    }

    function onChangeAmount(event: FormEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement;
        const value = target.value.replace(/\D/g, "");
        setAmount(value);
    }

    function onChangeStatus(value: string) {
        setIsActive(value === "active");
    }

    return (
        <WModalContent
            isActionDisabled={!code || !description || !amount}
            title={isEditMode ? "Edit Campaign" : "Add New Campaign"}
            label={isEditMode ? "Edit" : "Add"}
            modalAction={async () => await onSubmit()}
        >
            <WTextField isReadOnly={isEditMode} autoFocus className="max-w-24rem" wide label="Code" onChange={setCode}>
                <AInput value={code} />
            </WTextField>

            <WTextField wide label="Description" onChange={setDescription}>
                <AInput value={description} />
            </WTextField>

            <WTextField isReadOnly={isEditMode} wide label="Credit Amount" onChange={undefined}>
                <AInput value={amount} onChange={onChangeAmount} />
            </WTextField>

            <WTextField wide label="Type" onChange={undefined}>
                <WSelect
                    isDisabled={isEditMode}
                    valueKey={type}
                    getKey={(item) => item.id}
                    items={typeItems}
                    itemClassName={LR.item}
                    valueClassName={LR.value}
                    aria-label="Select type"
                    getTextValue={(item) => item.title}
                    onChange={(item) => setType(item.id)}
                >
                    {({ title }) => {
                        return <LeftRight title={title} />;
                    }}
                </WSelect>
            </WTextField>

            <WTextField wide label="Status" onChange={undefined}>
                <WSelect
                    valueKey={isActive ? "active" : "inactive"}
                    getKey={(item) => item.id}
                    items={statusItems}
                    itemClassName={LR.item}
                    valueClassName={LR.value}
                    aria-label="Select status"
                    getTextValue={(item) => item.title}
                    onChange={(item) => onChangeStatus(item.id)}
                >
                    {({ title }) => {
                        return <LeftRight title={title} />;
                    }}
                </WSelect>
            </WTextField>
        </WModalContent>
    );
}
